
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as agb7FiLjDb_1XZC50MXkbiQMEovqE3Q6TBhFfkhTLAdY_45gMeta } from "/vercel/path0/layers/base/pages/agb.vue?macro=true";
import { default as daten_45korrigierenHaBdxwkBz96_KCo_456hyaY_U0ybzXGFHzDgVLFhedYY4Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten-korrigieren.vue?macro=true";
import { default as finJulbLpg2tMx0KQDc5R0hIAehjD7AGTzvkxs_455H4lkEkMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/fin.vue?macro=true";
import { default as generellQsKywR7P2NVXzmM2Cch7slygbEsPu1JRHVYOaWK_45IOAMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/generell.vue?macro=true";
import { default as reservierung_QrOAjTGYzaoL92X0p_RaH64b65_45D477g19xTr_45NZdsMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/reservierung.vue?macro=true";
import { default as sicherheitscode_45kennzeichen_45hintenFZe_c6VT2qNcfPT3T4oq97RgNqxH8y9A0lcP4rr4PpAMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten.vue?macro=true";
import { default as sicherheitscode_45kennzeichen_45vorneMbjDDmfNtUYFWWs7zKIaQVp4SqfejQd0LzxQhgnix_QMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne.vue?macro=true";
import { default as sicherheitscode_45zb1NdapwoaLcNqbGgBkdr9M0sRgJgdKEbmit7KzX_5wd8MMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as indexrGLomjoebax1VtezmPonJOtoNwftFkNMdV_0A5wzuKEMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/index.vue?macro=true";
import { default as orderpBKGXDg7A4zU7NmeBt6HQiPwj9kzEhBSsVh7a0P827oMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/order.vue?macro=true";
import { default as fertigUTtA2GigRgnq86IakSWByKDaccXKK1gACyPlIeD8dFoMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/fertig.vue?macro=true";
import { default as index6hRTDNBW1v8oMxcpbeLaF4tfphsBzj65BnGHhJP3lPkMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/index.vue?macro=true";
import { default as sc_45hintenzQU6tm8_45IDQI4pQQEQKl3W8yJXVr9hTEiPkmloyWBGMMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-hinten.vue?macro=true";
import { default as sc_45vorn87sUtba03Z0MnJicBRkjPxHlxPxw8biljfacYHCw02IMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-vorn.vue?macro=true";
import { default as sc_45zb1ChLcc2KOc28z4QdagG8hptqFVd_YoFzP6K1oRmrBh2kMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-zb1.vue?macro=true";
import { default as zb1vAflX53Yr9YsbIczHWtSmaZ63LtSNyg49HY9XY1QXssMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/zb1.vue?macro=true";
import { default as vorbereitungGAubhjxZ2aU37PCpBnqpIBUbc7k4ynBRfWqVZSkTHl4Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/vorbereitung.vue?macro=true";
import { default as datenschutzr_456TXbFpdB_45ErFKNiC_4509FQ0sMntJ0DovoqteC0d_45IsMeta } from "/vercel/path0/layers/base/pages/datenschutz.vue?macro=true";
import { default as altes_45kennzeichen9ekYtMsicdoZ1qHCKFhFLuxw8h976etGr3FY9M8Row4Meta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/daten/altes-kennzeichen.vue?macro=true";
import { default as behoerdeEYBQ4fONye_pQ2YZrKm_W8_45TH0aKnMl6y02NZ9ksq38Meta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/daten/behoerde.vue?macro=true";
import { default as finXneDPvPwqDFx8BwTwVE0uUpkQyTZxRfZsD_45DI2YfK2QMeta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/daten/fin.vue?macro=true";
import { default as sicherheitscode_45zb1wr3kWsLiGVNfpn3ypq_4510H3efIODAJhw_45vQzfLAtgwoMeta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as orderNP3apoMw6B7z6FkC8rBJPNRflwGCa31vqIxLyQIFzk0Meta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/order.vue?macro=true";
import { default as vorbereitungwq17vajShpMujcdpPGQW4ka1efX0WE8x4ZKSW5xVJHQMeta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/vorbereitung.vue?macro=true";
import { default as impersonateF1HS3pgU97YGKuGwj8aV0YYSLaMIMyre3eZhx8nZ4IAMeta } from "/vercel/path0/layers/zulassung/pages/impersonate.vue?macro=true";
import { default as impressumiknixS_3mKoW2_TGW_456rsz_4540OtD_C2IQ7akYvDGSRAMeta } from "/vercel/path0/layers/base/pages/impressum.vue?macro=true";
import { default as indexR75Qi42fXWtvCz1hSXiLsAOoWjXGmwTOnc1OR_45PTGcQMeta } from "/vercel/path0/apps/zulassung-digital/pages/index.vue?macro=true";
import { default as _91district_93UWI_D7acSQ_XAj_2BuU5e5pbScGkwSK_45MFi6Rqwkri8Meta } from "/vercel/path0/apps/zulassung-digital/pages/landkreis/[district].vue?macro=true";
import { default as landkreiseDZNGWhOnO3LqkWJh3qoehoNWrQgvx6ot8kQ3G862y_45kMeta } from "/vercel/path0/apps/zulassung-digital/pages/landkreise.vue?macro=true";
import { default as fahrzeugWqabtNxTBcGWuP95cjfGKLa3bHmPA_45gfFXFhFFWmQ7MMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/fahrzeug.vue?macro=true";
import { default as finfcUASyXN8QSmwUady5QJcLHngW_dIpJ55xU6y_45esLNgMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/fin.vue?macro=true";
import { default as indexL0Udhn66KyQyiXy2rO6ExEEOAa_452wpSnVxq2I6ikXk8Meta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/index.vue?macro=true";
import { default as kfz_45kennzeichenK49Kn6rssQxIrdrlSyPUVy0uevuHMoZJgxJ65HxYHJcMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/kfz-kennzeichen.vue?macro=true";
import { default as kfz_45steuerhTpSHxSZQGOL4IDbDLFofAmFUuO5ee4H_nqJjkrpRyYMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/kfz-steuer.vue?macro=true";
import { default as kontaktkVmRxcuespgjuVJFzo8slGfubIIx4OmdLUQWFArQr2QMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/kontakt.vue?macro=true";
import { default as neues_45kennzeicheny9a6OHjKVceGHuCa8Ntnub_FMxDNmk5BjePs_W91rT8Meta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/neues-kennzeichen.vue?macro=true";
import { default as orderZy13gwnfFEJc_2J2AmHHZ6Dwh3vPqySgmd9hs9sp14MMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/order.vue?macro=true";
import { default as reservierunga06SaCRiVYGv2BYuso1EIU0Tsay3VEPUu1t5LtVQCZwMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/reservierung.vue?macro=true";
import { default as sicherheitscode_45zb2vjmQv4hxEf1L8PBdy7OlB7ILDvR9eTWHaZNTdgPjMRcMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/sicherheitscode-zb2.vue?macro=true";
import { default as umweltplaketteIwoJHKYMRkRw2jiMII7x_8cbTwGSu8fejYU49R2wr84Meta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/umweltplakette.vue?macro=true";
import { default as versicherung_45steuersk1G4GDn4nU5yJYvZfUE8PJ1qHlI3NyNQN8yEtLIb5gMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/versicherung-steuer.vue?macro=true";
import { default as vorbereitungEr4s_45ryw5OMy4cpyAkwW8BlMxRm5xkjwIqu2caiKXIwMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/vorbereitung.vue?macro=true";
import { default as wunschkennzeichen_45behoerdej2L1kZeys4s7MVSn_LgxBltpVmLc4w5Rgyg7d7j8ADIMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/wunschkennzeichen-behoerde.vue?macro=true";
import { default as wunschkennzeichen_45portalIhTN92ZTD5fTnVOKKwTx2yab_45woI4ohfsnzJUIX54HIMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/wunschkennzeichen-portal.vue?macro=true";
import { default as wunschkennzeichen_45suchec5WHtirF72nUuHbCbduQk8c72VpDevoKHwN8VGT_45B9cMeta } from "/vercel/path0/layers/zulassung/pages/neuzulassung/wunschkennzeichen-suche.vue?macro=true";
import { default as orderIOWJNqMniN1EL_45ypRAevqZ3agh_xxFZpQ1LGKafQa_YMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/order.vue?macro=true";
import { default as otpp_45s2u_45lCvbBTwGY_DsgdayFRO_451O00t5MU6q0ROMYaoMeta } from "/vercel/path0/layers/zulassung/pages/otp.vue?macro=true";
import { default as _91city_93GxeJ8XJ75DtTAB0aql7vfZtw43e3Qv5ckR24bjyea60Meta } from "/vercel/path0/apps/zulassung-digital/pages/stadt/[city].vue?macro=true";
import { default as staedteOa_45c5kGt75iYqnZoVL5hFrKFjO7kVfkV6R18sWSs4L8Meta } from "/vercel/path0/apps/zulassung-digital/pages/staedte.vue?macro=true";
import { default as ummeldung_45auswahlUugDV07v8edF91Lv7ZV1lR83wENHRO4R3kjr4fMjizoMeta } from "/vercel/path0/layers/zulassung/pages/ummeldung-auswahl.vue?macro=true";
import { default as _91citySlug_93FJFs_450jTZLsJqi7TU9tU7MDgiPpnBUNIlIS7FmB6M4gMeta } from "/vercel/path0/apps/zulassung-digital/pages/ummeldung/[citySlug].vue?macro=true";
import { default as altes_45kennzeichen_45T0DL7Q08vcmRLqsiC21RzhTITzCbGxYhfKFxS_45kACsMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/altes-kennzeichen.vue?macro=true";
import { default as behoerdeyHd4E1pqCQCRW6hPdmMsTigi2fX_45sbh_45UXVjw88w7gcMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/behoerde.vue?macro=true";
import { default as finXWb6vV_L7RCu6wXUaX7F7HZInSPVZ4GL4h7cM0Q2GGMMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/fin.vue?macro=true";
import { default as kfz_45steuer5cdmKrgGYYMPgINBM_v11pd4u9ywyOhctVi_45wZLHo_cMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/kfz-steuer.vue?macro=true";
import { default as neues_45kennzeichenp7sd8GyudciXfs3NdJoP9BO6XO2p8npcg54LKRx0_1UMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/neues-kennzeichen.vue?macro=true";
import { default as reservierungSsQrrbt4BlRgvIaMn14GyFCbDXKt9d4ObA7bK2uvmAEMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/reservierung.vue?macro=true";
import { default as sicherheitscode_45hintenQDNX55EPF1l6avialHlEd8goNMgtq7m0KTFcaJa3AG8Meta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-hinten.vue?macro=true";
import { default as sicherheitscode_45vorneB7HO9eyaGnHxUZltF4B9jyq4LKtITus9mMLTaGld_45gsMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-vorne.vue?macro=true";
import { default as sicherheitscode_45zb1EwrL9jA8PYgYrarXKy7pfDJR9IAX_45OFAQe5xenfPjkIMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as sicherheitscode_45zb26cBiOonO7GCdzX_459bioqnqjUi_gcR7M9VqZQ0_45foonwMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-zb2.vue?macro=true";
import { default as versicherung_45steuerE8D17taKO_tQsNhyD1Z5z8xq9JyZHlmjQ27InBCUuNoMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/versicherung-steuer.vue?macro=true";
import { default as wunschkennzeichen_45behoerdeJZyGfKAIpWdq5yWvk7FAsEJD6Qux3gl7QDjAuwdIm4AMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/wunschkennzeichen-behoerde.vue?macro=true";
import { default as wunschkennzeichen_45portaliFttZwdBsAYqxSvjKWqfuy17hhwgx4A6grsZ_45thimiQMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/wunschkennzeichen-portal.vue?macro=true";
import { default as wunschkennzeichen_45sucheXiEebE8Wb4saNbcLerckVDS4Fbwf_K5LjhgSIn_Q8rQMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/wunschkennzeichen-suche.vue?macro=true";
import { default as fahrzeugfs39gNfFZhTCEgJjEvgLy6M0mR6vQ960pArHZPjL_45MsMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/fahrzeug.vue?macro=true";
import { default as indextjMlZlH4BgEKy3LUypdaHNSSIokTyZIX0bJjeM7bz5UMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/index.vue?macro=true";
import { default as kfz_45kennzeichensMy9eww9HyNSfJDy5UbzVkkXtjO_hqNt8VJ6PJWxvr4Meta } from "/vercel/path0/layers/zulassung/pages/umschreibung/kfz-kennzeichen.vue?macro=true";
import { default as orderEyTpfQLRAXSqvlsjPc0yQ7nvFnQdhMzfELpFEheaSoEMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/order.vue?macro=true";
import { default as umweltplakettec3_4L3vzlqnfSBgH_LBWVFiWY0eju9s1J7gjp3dDWBEMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/umweltplakette.vue?macro=true";
import { default as vorbereitung9GN_Rx3ihzeNkHKSrSgOF1tGJTwYd85IBkFL_s92LGMMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/vorbereitung.vue?macro=true";
import { default as _91citySlug_93yMYAOcDBweVe_45LvpeHHWJVVtf68do12NFFstcvHZN2gMeta } from "/vercel/path0/apps/zulassung-digital/pages/umweltplakette/[citySlug].vue?macro=true";
import { default as checkoutEz6Q0M6wYwWa_gyfCDmcDw0OVJrbqyrirgYe9mie6sQMeta } from "/vercel/path0/layers/wkz-checkout/pages/umweltplakette/checkout.vue?macro=true";
import { default as erfolgvU7DHDGwdpgD3miK3hiWgRv858kurhs8xerAB7igJgwMeta } from "/vercel/path0/layers/wkz-checkout/pages/umweltplakette/erfolg.vue?macro=true";
import { default as index4Wxoz9ybD3oPpBFMWk6XnI_45sIQi4_yZXcUrIskODmnQMeta } from "/vercel/path0/apps/zulassung-digital/pages/umweltplakette/index.vue?macro=true";
import { default as uploadBSnDIWjEJCnLmCYE5qP5WDAUrOCp5nOKDKYmd_45NSGCIMeta } from "/vercel/path0/layers/wkz-checkout/pages/umweltplakette/upload.vue?macro=true";
import { default as umzugssUguebme00tu_45CXz_45kWaWSOq5nOxBHMQQjXI79aJeIMeta } from "/vercel/path0/layers/zulassung/pages/umzug.vue?macro=true";
import { default as uploadqFCT_4530W5nOZyWLf91VhnqAkNrvOJ7tlkvFfKXyClpsMeta } from "/vercel/path0/layers/zulassung/pages/upload.vue?macro=true";
import { default as widerrufsrechtgGuMgqxaZuqvKWe74ZVPDU_45Z_hvg_x_45c7eSYdwewh60Meta } from "/vercel/path0/layers/base/pages/widerrufsrecht.vue?macro=true";
import { default as altes_45kennzeichenR75SdNI2zKGy226oStlshBIa4aBfwx1rnazC6ofBbyAMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/altes-kennzeichen.vue?macro=true";
import { default as behoerdeDe8pC33KU38k3zU7oRdirraUUsuhM_45bocJJ9basBuOQMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/behoerde.vue?macro=true";
import { default as finSj2ZC0SSx7Iaf420izc5cF6DIUzgISzjx6DRpVkZyc4Meta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/fin.vue?macro=true";
import { default as kfz_45steuerXlfCQpd4o696brhkwHcc47WWJxo1DrBfgLwswt8E_tYMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/kfz-steuer.vue?macro=true";
import { default as neues_45kennzeichenvJRKNkbrbrm9ctflfeWMW6fDMxWL_03gTEJJyxwJq6QMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/neues-kennzeichen.vue?macro=true";
import { default as reservierungtm29T0QyDDcaRy9AvhBNIXM4F425X4IqkLON_45dM_45M5AMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/reservierung.vue?macro=true";
import { default as sicherheitscode_45zb1AJ7AN_Z46XUz8ydHK48MaL_WVrZcTCfTm9R_45lj3NF_45IMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as sicherheitscode_45zb2QMfHXhsYlgX3RWkHwmOp4MDr_UwC104egJn_TVpCmAEMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/sicherheitscode-zb2.vue?macro=true";
import { default as versicherung_45steueruC7cgIdwtf5ysbG_uQcqbY0kOxxpryIVN_45tkk0iiIiAMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/versicherung-steuer.vue?macro=true";
import { default as wunschkennzeichen_45behoerdec1ep8OFckPXABk3A2OJM0QU5_PAgGY1VMAuIn8qKxZMMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-behoerde.vue?macro=true";
import { default as wunschkennzeichen_45portal_45273lkdgmo9Jx_0lvU0firLQCB1thPraknj8GaTPhTsMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-portal.vue?macro=true";
import { default as wunschkennzeichen_45sucheJHu_45KQuWJcUs5xIp4YGDvmGDNzmOW_lr2pXn5RePYbgMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-suche.vue?macro=true";
import { default as fahrzeug8eI5nwhwzmZ_WGcrE_458tY_45AbE3suTQ0WfVunV3uG0bMMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/fahrzeug.vue?macro=true";
import { default as indexyaDr2FvAxpc_45hcTKjwLIirTf_45extAgk7yWAnQh_ZqNIMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/index.vue?macro=true";
import { default as kfz_45kennzeichenf8hL22GQtU7gCw7xLsCUgbGvjLZJJdxU6MpI9cXqI5YMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/kfz-kennzeichen.vue?macro=true";
import { default as orderm4GNzX2JgiPnOEgehbhDvEEn9XGhWpQkpZ8okJkkihkMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/order.vue?macro=true";
import { default as umweltplaketteTD_F6zyhqtdsJMz4YqMK0WQ0nbhOnQtvKbmJqIaYDbAMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/umweltplakette.vue?macro=true";
import { default as vorbereitungK_F7pgDQaN8alRoMYdmZPZnRcPYuFOXI_45AdQMy41Wt0Meta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/vorbereitung.vue?macro=true";
import { default as _91citySlug_93ZIHQ4cQ1dhS0tmPJLw_hYVsqnKGCNlTy5Zgv865taBYMeta } from "/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/[citySlug].vue?macro=true";
import { default as _91districtSlug_93_457116ZZJxUommEwXTNN3jtyD4cIjttZbnj4GQr4IFVkMeta } from "/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/bezirk/[districtSlug].vue?macro=true";
import { default as checkoutok_t_45ETLo9J8xF_45E4c4ZJnCnVyuz1nsyHIVnKvU8jkcMeta } from "/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/checkout.vue?macro=true";
import { default as erfolgtcZLoS_V7qPCGtYNxfZgph28clc_JEda6qHHt5kmmfoMeta } from "/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/erfolg.vue?macro=true";
import { default as indexyHI2kKy6lpLh8726FZ7CB_2IcAErB8EiRBLxOiwFHmIMeta } from "/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/index.vue?macro=true";
import { default as reservierenwSNk9AxzSYE8juA4D8ELv2JUjDYfP1sgmIcItNOpBM0Meta } from "/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/reservieren.vue?macro=true";
import { default as zulassungsstatusIifh2HQwJLP4sw6w62LVpaocy7qOGlDHGFq7sdtml2AMeta } from "/vercel/path0/layers/zulassung/pages/zulassungsstatus.vue?macro=true";
import { default as _91citySlug_93XJE_EI6_0LE63doeTkvDjW_vV531Dcmno2UDleIgE_450Meta } from "/vercel/path0/apps/zulassung-digital/pages/zulassungsstelle/[citySlug].vue?macro=true";
import { default as zustandSZR95qXUvDCkyTdA9txatz7opIMAn4ejgn9Ks3C91YcMeta } from "/vercel/path0/layers/zulassung/pages/zustand.vue?macro=true";
export default [
  {
    name: "agb",
    path: "/agb",
    meta: agb7FiLjDb_1XZC50MXkbiQMEovqE3Q6TBhFfkhTLAdY_45gMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/agb.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-korrigieren",
    path: "/ausserbetriebsetzung/daten-korrigieren",
    meta: daten_45korrigierenHaBdxwkBz96_KCo_456hyaY_U0ybzXGFHzDgVLFhedYY4Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten-korrigieren.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-fin",
    path: "/ausserbetriebsetzung/daten/fin",
    meta: finJulbLpg2tMx0KQDc5R0hIAehjD7AGTzvkxs_455H4lkEkMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/fin.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-generell",
    path: "/ausserbetriebsetzung/daten/generell",
    meta: generellQsKywR7P2NVXzmM2Cch7slygbEsPu1JRHVYOaWK_45IOAMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/generell.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-reservierung",
    path: "/ausserbetriebsetzung/daten/reservierung",
    meta: reservierung_QrOAjTGYzaoL92X0p_RaH64b65_45D477g19xTr_45NZdsMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/reservierung.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-kennzeichen-hinten",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten",
    meta: sicherheitscode_45kennzeichen_45hintenFZe_c6VT2qNcfPT3T4oq97RgNqxH8y9A0lcP4rr4PpAMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-kennzeichen-vorne",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne",
    meta: sicherheitscode_45kennzeichen_45vorneMbjDDmfNtUYFWWs7zKIaQVp4SqfejQd0LzxQhgnix_QMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-zb1",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-zb1",
    meta: sicherheitscode_45zb1NdapwoaLcNqbGgBkdr9M0sRgJgdKEbmit7KzX_5wd8MMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-zb1.vue")
  },
  {
    name: "ausserbetriebsetzung",
    path: "/ausserbetriebsetzung",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/index.vue")
  },
  {
    name: "ausserbetriebsetzung-order",
    path: "/ausserbetriebsetzung/order",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/order.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-fertig",
    path: "/ausserbetriebsetzung/upload/fertig",
    meta: fertigUTtA2GigRgnq86IakSWByKDaccXKK1gACyPlIeD8dFoMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/fertig.vue")
  },
  {
    name: "ausserbetriebsetzung-upload",
    path: "/ausserbetriebsetzung/upload",
    meta: index6hRTDNBW1v8oMxcpbeLaF4tfphsBzj65BnGHhJP3lPkMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/index.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-hinten",
    path: "/ausserbetriebsetzung/upload/sc-hinten",
    meta: sc_45hintenzQU6tm8_45IDQI4pQQEQKl3W8yJXVr9hTEiPkmloyWBGMMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-hinten.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-vorn",
    path: "/ausserbetriebsetzung/upload/sc-vorn",
    meta: sc_45vorn87sUtba03Z0MnJicBRkjPxHlxPxw8biljfacYHCw02IMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-vorn.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-zb1",
    path: "/ausserbetriebsetzung/upload/sc-zb1",
    meta: sc_45zb1ChLcc2KOc28z4QdagG8hptqFVd_YoFzP6K1oRmrBh2kMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-zb1.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-zb1",
    path: "/ausserbetriebsetzung/upload/zb1",
    meta: zb1vAflX53Yr9YsbIczHWtSmaZ63LtSNyg49HY9XY1QXssMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/zb1.vue")
  },
  {
    name: "ausserbetriebsetzung-vorbereitung",
    path: "/ausserbetriebsetzung/vorbereitung",
    meta: vorbereitungGAubhjxZ2aU37PCpBnqpIBUbc7k4ynBRfWqVZSkTHl4Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/vorbereitung.vue")
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    meta: datenschutzr_456TXbFpdB_45ErFKNiC_4509FQ0sMntJ0DovoqteC0d_45IsMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/datenschutz.vue")
  },
  {
    name: "halteraenderung-daten-altes-kennzeichen",
    path: "/halteraenderung/daten/altes-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/daten/altes-kennzeichen.vue")
  },
  {
    name: "halteraenderung-daten-behoerde",
    path: "/halteraenderung/daten/behoerde",
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/daten/behoerde.vue")
  },
  {
    name: "halteraenderung-daten-fin",
    path: "/halteraenderung/daten/fin",
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/daten/fin.vue")
  },
  {
    name: "halteraenderung-daten-sicherheitscode-zb1",
    path: "/halteraenderung/daten/sicherheitscode-zb1",
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/daten/sicherheitscode-zb1.vue")
  },
  {
    name: "halteraenderung-order",
    path: "/halteraenderung/order",
    meta: orderNP3apoMw6B7z6FkC8rBJPNRflwGCa31vqIxLyQIFzk0Meta || {},
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/order.vue")
  },
  {
    name: "halteraenderung-vorbereitung",
    path: "/halteraenderung/vorbereitung",
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/vorbereitung.vue")
  },
  {
    name: "impersonate",
    path: "/impersonate",
    component: () => import("/vercel/path0/layers/zulassung/pages/impersonate.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    meta: impressumiknixS_3mKoW2_TGW_456rsz_4540OtD_C2IQ7akYvDGSRAMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexR75Qi42fXWtvCz1hSXiLsAOoWjXGmwTOnc1OR_45PTGcQMeta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/index.vue")
  },
  {
    name: "landkreis-district",
    path: "/landkreis/:district()",
    meta: _91district_93UWI_D7acSQ_XAj_2BuU5e5pbScGkwSK_45MFi6Rqwkri8Meta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/landkreis/[district].vue")
  },
  {
    name: "landkreise",
    path: "/landkreise",
    meta: landkreiseDZNGWhOnO3LqkWJh3qoehoNWrQgvx6ot8kQ3G862y_45kMeta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/landkreise.vue")
  },
  {
    name: "neuzulassung-fahrzeug",
    path: "/neuzulassung/fahrzeug",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/fahrzeug.vue")
  },
  {
    name: "neuzulassung-fin",
    path: "/neuzulassung/fin",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/fin.vue")
  },
  {
    name: "neuzulassung",
    path: "/neuzulassung",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/index.vue")
  },
  {
    name: "neuzulassung-kfz-kennzeichen",
    path: "/neuzulassung/kfz-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/kfz-kennzeichen.vue")
  },
  {
    name: "neuzulassung-kfz-steuer",
    path: "/neuzulassung/kfz-steuer",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/kfz-steuer.vue")
  },
  {
    name: "neuzulassung-kontakt",
    path: "/neuzulassung/kontakt",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/kontakt.vue")
  },
  {
    name: "neuzulassung-neues-kennzeichen",
    path: "/neuzulassung/neues-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/neues-kennzeichen.vue")
  },
  {
    name: "neuzulassung-order",
    path: "/neuzulassung/order",
    meta: orderZy13gwnfFEJc_2J2AmHHZ6Dwh3vPqySgmd9hs9sp14MMeta || {},
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/order.vue")
  },
  {
    name: "neuzulassung-reservierung",
    path: "/neuzulassung/reservierung",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/reservierung.vue")
  },
  {
    name: "neuzulassung-sicherheitscode-zb2",
    path: "/neuzulassung/sicherheitscode-zb2",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/sicherheitscode-zb2.vue")
  },
  {
    name: "neuzulassung-umweltplakette",
    path: "/neuzulassung/umweltplakette",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/umweltplakette.vue")
  },
  {
    name: "neuzulassung-versicherung-steuer",
    path: "/neuzulassung/versicherung-steuer",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/versicherung-steuer.vue")
  },
  {
    name: "neuzulassung-vorbereitung",
    path: "/neuzulassung/vorbereitung",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/vorbereitung.vue")
  },
  {
    name: "neuzulassung-wunschkennzeichen-behoerde",
    path: "/neuzulassung/wunschkennzeichen-behoerde",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/wunschkennzeichen-behoerde.vue")
  },
  {
    name: "neuzulassung-wunschkennzeichen-portal",
    path: "/neuzulassung/wunschkennzeichen-portal",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/wunschkennzeichen-portal.vue")
  },
  {
    name: "neuzulassung-wunschkennzeichen-suche",
    path: "/neuzulassung/wunschkennzeichen-suche",
    component: () => import("/vercel/path0/layers/zulassung/pages/neuzulassung/wunschkennzeichen-suche.vue")
  },
  {
    name: "order",
    path: "/order",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/order.vue")
  },
  {
    name: "otp",
    path: "/otp",
    component: () => import("/vercel/path0/layers/zulassung/pages/otp.vue")
  },
  {
    name: "stadt-city",
    path: "/stadt/:city()",
    meta: _91city_93GxeJ8XJ75DtTAB0aql7vfZtw43e3Qv5ckR24bjyea60Meta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/stadt/[city].vue")
  },
  {
    name: "staedte",
    path: "/staedte",
    meta: staedteOa_45c5kGt75iYqnZoVL5hFrKFjO7kVfkV6R18sWSs4L8Meta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/staedte.vue")
  },
  {
    name: "ummeldung-auswahl",
    path: "/ummeldung-auswahl",
    component: () => import("/vercel/path0/layers/zulassung/pages/ummeldung-auswahl.vue")
  },
  {
    name: "ummeldung-citySlug",
    path: "/ummeldung/:citySlug()",
    meta: _91citySlug_93FJFs_450jTZLsJqi7TU9tU7MDgiPpnBUNIlIS7FmB6M4gMeta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/ummeldung/[citySlug].vue")
  },
  {
    name: "umschreibung-daten-altes-kennzeichen",
    path: "/umschreibung/daten/altes-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/altes-kennzeichen.vue")
  },
  {
    name: "umschreibung-daten-behoerde",
    path: "/umschreibung/daten/behoerde",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/behoerde.vue")
  },
  {
    name: "umschreibung-daten-fin",
    path: "/umschreibung/daten/fin",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/fin.vue")
  },
  {
    name: "umschreibung-daten-kfz-steuer",
    path: "/umschreibung/daten/kfz-steuer",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/kfz-steuer.vue")
  },
  {
    name: "umschreibung-daten-neues-kennzeichen",
    path: "/umschreibung/daten/neues-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/neues-kennzeichen.vue")
  },
  {
    name: "umschreibung-daten-reservierung",
    path: "/umschreibung/daten/reservierung",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/reservierung.vue")
  },
  {
    name: "umschreibung-daten-sicherheitscode-hinten",
    path: "/umschreibung/daten/sicherheitscode-hinten",
    meta: sicherheitscode_45hintenQDNX55EPF1l6avialHlEd8goNMgtq7m0KTFcaJa3AG8Meta || {},
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-hinten.vue")
  },
  {
    name: "umschreibung-daten-sicherheitscode-vorne",
    path: "/umschreibung/daten/sicherheitscode-vorne",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-vorne.vue")
  },
  {
    name: "umschreibung-daten-sicherheitscode-zb1",
    path: "/umschreibung/daten/sicherheitscode-zb1",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-zb1.vue")
  },
  {
    name: "umschreibung-daten-sicherheitscode-zb2",
    path: "/umschreibung/daten/sicherheitscode-zb2",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-zb2.vue")
  },
  {
    name: "umschreibung-daten-versicherung-steuer",
    path: "/umschreibung/daten/versicherung-steuer",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/versicherung-steuer.vue")
  },
  {
    name: "umschreibung-daten-wunschkennzeichen-behoerde",
    path: "/umschreibung/daten/wunschkennzeichen-behoerde",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/wunschkennzeichen-behoerde.vue")
  },
  {
    name: "umschreibung-daten-wunschkennzeichen-portal",
    path: "/umschreibung/daten/wunschkennzeichen-portal",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/wunschkennzeichen-portal.vue")
  },
  {
    name: "umschreibung-daten-wunschkennzeichen-suche",
    path: "/umschreibung/daten/wunschkennzeichen-suche",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/wunschkennzeichen-suche.vue")
  },
  {
    name: "umschreibung-fahrzeug",
    path: "/umschreibung/fahrzeug",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/fahrzeug.vue")
  },
  {
    name: "umschreibung",
    path: "/umschreibung",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/index.vue")
  },
  {
    name: "umschreibung-kfz-kennzeichen",
    path: "/umschreibung/kfz-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/kfz-kennzeichen.vue")
  },
  {
    name: "umschreibung-order",
    path: "/umschreibung/order",
    meta: orderEyTpfQLRAXSqvlsjPc0yQ7nvFnQdhMzfELpFEheaSoEMeta || {},
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/order.vue")
  },
  {
    name: "umschreibung-umweltplakette",
    path: "/umschreibung/umweltplakette",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/umweltplakette.vue")
  },
  {
    name: "umschreibung-vorbereitung",
    path: "/umschreibung/vorbereitung",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/vorbereitung.vue")
  },
  {
    name: "umweltplakette-citySlug",
    path: "/umweltplakette/:citySlug()",
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/umweltplakette/[citySlug].vue")
  },
  {
    name: "umweltplakette-checkout",
    path: "/umweltplakette/checkout",
    meta: checkoutEz6Q0M6wYwWa_gyfCDmcDw0OVJrbqyrirgYe9mie6sQMeta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/umweltplakette/checkout.vue")
  },
  {
    name: "umweltplakette-erfolg",
    path: "/umweltplakette/erfolg",
    meta: erfolgvU7DHDGwdpgD3miK3hiWgRv858kurhs8xerAB7igJgwMeta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/umweltplakette/erfolg.vue")
  },
  {
    name: "umweltplakette",
    path: "/umweltplakette",
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/umweltplakette/index.vue")
  },
  {
    name: "umweltplakette-upload",
    path: "/umweltplakette/upload",
    meta: uploadBSnDIWjEJCnLmCYE5qP5WDAUrOCp5nOKDKYmd_45NSGCIMeta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/umweltplakette/upload.vue")
  },
  {
    name: "umzug",
    path: "/umzug",
    component: () => import("/vercel/path0/layers/zulassung/pages/umzug.vue")
  },
  {
    name: "upload",
    path: "/upload",
    meta: uploadqFCT_4530W5nOZyWLf91VhnqAkNrvOJ7tlkvFfKXyClpsMeta || {},
    component: () => import("/vercel/path0/layers/zulassung/pages/upload.vue")
  },
  {
    name: "widerrufsrecht",
    path: "/widerrufsrecht",
    meta: widerrufsrechtgGuMgqxaZuqvKWe74ZVPDU_45Z_hvg_x_45c7eSYdwewh60Meta || {},
    component: () => import("/vercel/path0/layers/base/pages/widerrufsrecht.vue")
  },
  {
    name: "wiederzulassung-daten-altes-kennzeichen",
    path: "/wiederzulassung/daten/altes-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/altes-kennzeichen.vue")
  },
  {
    name: "wiederzulassung-daten-behoerde",
    path: "/wiederzulassung/daten/behoerde",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/behoerde.vue")
  },
  {
    name: "wiederzulassung-daten-fin",
    path: "/wiederzulassung/daten/fin",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/fin.vue")
  },
  {
    name: "wiederzulassung-daten-kfz-steuer",
    path: "/wiederzulassung/daten/kfz-steuer",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/kfz-steuer.vue")
  },
  {
    name: "wiederzulassung-daten-neues-kennzeichen",
    path: "/wiederzulassung/daten/neues-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/neues-kennzeichen.vue")
  },
  {
    name: "wiederzulassung-daten-reservierung",
    path: "/wiederzulassung/daten/reservierung",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/reservierung.vue")
  },
  {
    name: "wiederzulassung-daten-sicherheitscode-zb1",
    path: "/wiederzulassung/daten/sicherheitscode-zb1",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/sicherheitscode-zb1.vue")
  },
  {
    name: "wiederzulassung-daten-sicherheitscode-zb2",
    path: "/wiederzulassung/daten/sicherheitscode-zb2",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/sicherheitscode-zb2.vue")
  },
  {
    name: "wiederzulassung-daten-versicherung-steuer",
    path: "/wiederzulassung/daten/versicherung-steuer",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/versicherung-steuer.vue")
  },
  {
    name: "wiederzulassung-daten-wunschkennzeichen-behoerde",
    path: "/wiederzulassung/daten/wunschkennzeichen-behoerde",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-behoerde.vue")
  },
  {
    name: "wiederzulassung-daten-wunschkennzeichen-portal",
    path: "/wiederzulassung/daten/wunschkennzeichen-portal",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-portal.vue")
  },
  {
    name: "wiederzulassung-daten-wunschkennzeichen-suche",
    path: "/wiederzulassung/daten/wunschkennzeichen-suche",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-suche.vue")
  },
  {
    name: "wiederzulassung-fahrzeug",
    path: "/wiederzulassung/fahrzeug",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/fahrzeug.vue")
  },
  {
    name: "wiederzulassung",
    path: "/wiederzulassung",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/index.vue")
  },
  {
    name: "wiederzulassung-kfz-kennzeichen",
    path: "/wiederzulassung/kfz-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/kfz-kennzeichen.vue")
  },
  {
    name: "wiederzulassung-order",
    path: "/wiederzulassung/order",
    meta: orderm4GNzX2JgiPnOEgehbhDvEEn9XGhWpQkpZ8okJkkihkMeta || {},
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/order.vue")
  },
  {
    name: "wiederzulassung-umweltplakette",
    path: "/wiederzulassung/umweltplakette",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/umweltplakette.vue")
  },
  {
    name: "wiederzulassung-vorbereitung",
    path: "/wiederzulassung/vorbereitung",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/vorbereitung.vue")
  },
  {
    name: "wunschkennzeichen-citySlug",
    path: "/wunschkennzeichen/:citySlug()",
    meta: _91citySlug_93ZIHQ4cQ1dhS0tmPJLw_hYVsqnKGCNlTy5Zgv865taBYMeta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/[citySlug].vue")
  },
  {
    name: "wunschkennzeichen-bezirk-districtSlug",
    path: "/wunschkennzeichen/bezirk/:districtSlug()",
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/bezirk/[districtSlug].vue")
  },
  {
    name: "wunschkennzeichen-checkout",
    path: "/wunschkennzeichen/checkout",
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/checkout.vue")
  },
  {
    name: "wunschkennzeichen-erfolg",
    path: "/wunschkennzeichen/erfolg",
    meta: erfolgtcZLoS_V7qPCGtYNxfZgph28clc_JEda6qHHt5kmmfoMeta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/erfolg.vue")
  },
  {
    name: "wunschkennzeichen",
    path: "/wunschkennzeichen",
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/index.vue")
  },
  {
    name: "wunschkennzeichen-reservieren",
    path: "/wunschkennzeichen/reservieren",
    meta: reservierenwSNk9AxzSYE8juA4D8ELv2JUjDYfP1sgmIcItNOpBM0Meta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/reservieren.vue")
  },
  {
    name: "zulassungsstatus",
    path: "/zulassungsstatus",
    component: () => import("/vercel/path0/layers/zulassung/pages/zulassungsstatus.vue")
  },
  {
    name: "zulassungsstelle-citySlug",
    path: "/zulassungsstelle/:citySlug()",
    meta: _91citySlug_93XJE_EI6_0LE63doeTkvDjW_vV531Dcmno2UDleIgE_450Meta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/zulassungsstelle/[citySlug].vue")
  },
  {
    name: "zustand",
    path: "/zustand",
    component: () => import("/vercel/path0/layers/zulassung/pages/zustand.vue")
  }
]