import type { VehicleType } from '@gks/prisma'

export function getStrongVehicleType (vehicle: VehicleType): 'car' | 'motorcycle' {
  switch (vehicle) {
    case 'motorcycle':
      return 'motorcycle'
    case 'car':
    case 'trailer':
      return 'car'
    default:
      return ((x: never) => x)(vehicle)
  }
}
